import { FC } from 'react';
import Image from 'next/image';
import RunImage from './image.png';

type RunnerImageProps = {
	className?: string;
	sizes?: string;
};

const RunnerImage: FC<RunnerImageProps> = ({ className, sizes }) => {
	return (
		<div className={className}>
			<Image src={RunImage} alt="" sizes={sizes} />
		</div>
	);
};

export default RunnerImage;
