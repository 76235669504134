import { FC } from 'react';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Head from 'next/head';
import styles from './style.module.css';
import { absoluteUrl, walksLinkProps } from '../../utilites/linkProps';
import getLocale from '../../utilites/locale';
import Logo from '../Logo';
import RunnerImage from '../RunnerImage';
import RunnerImageBroken from '../RunnerImageBroken';
import MainButton from '../MainButton';
import Korpen from '../Korpen';
import Hero from '../Hero';
import LanguageSelector from '../LanguageSelector';

type HomepageProps = {};

const Homepage: FC<HomepageProps> = ({}) => {
	const { locale } = useRouter();
	return (
		<div className={clsx(styles.home, 'no-margin-bottom')}>
			<Head>
				<link rel="canonical" href={absoluteUrl('/', getLocale(locale))} />
			</Head>
			<main className={styles.main}>
				<LanguageSelector className={styles.language} />
				<div className={styles.content}>
					<Hero size="large">
						<Trans>Välkommen till världens största tipspromenad</Trans>
					</Hero>
					<MainButton
						className={styles.link}
						element="a"
						{...walksLinkProps(getLocale(locale))}
					>
						<Trans>STARTA!</Trans>
					</MainButton>
				</div>
				<RunnerImage
					className={clsx(styles.image, styles.runnerImage)}
					sizes="(max-width: 575px) 58vmin, 16.7rem"
				/>
				<RunnerImageBroken
					className={clsx(styles.image, styles.runnerImageBroken)}
					sizes="(max-width: 575px) 69vmin, 19.8rem"
				/>
				<div className={styles.logos}>
					<div className={styles.korpen}>
						<Korpen />
					</div>
					<Logo />
				</div>
			</main>
		</div>
	);
};

export default Homepage;
