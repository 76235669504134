import { FC } from 'react';
import { useRouter } from 'next/router';
import Toggle from '../Toggle';

type LanguageSelectorProps = {
	className?: string;
};

const LanguageSelector: FC<LanguageSelectorProps> = ({ className }) => {
	const router = useRouter();

	/**
	 * @todo This should be links with hreflang attributes
	 */
	const handleChange = (checked: boolean) => {
		router.push(router.pathname, router.asPath, {
			locale: checked ? 'en' : 'sv',
		});
	};

	return (
		<Toggle
			className={className}
			checked={router.locale === 'sv'}
			on="SV"
			off="EN"
			onChange={handleChange}
		/>
	);
};

export default LanguageSelector;
