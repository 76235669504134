import { FC } from 'react';
import Runner from '../Runner';
import styles from './style.module.css';
import clsx from 'clsx';

type HeroProps = {
	size: 'small' | 'large';
	children: React.ReactNode;
};

const Hero: FC<HeroProps> = ({ size, children }) => {
	return (
		<div
			className={clsx(styles.hero, size === 'small' ? styles.heroSmall : '')}
		>
			<Runner />
			<h1 className={styles.heading}>{children}</h1>
		</div>
	);
};

export default Hero;
