import { NextPage } from 'next';
import Homepage from '../components/Homepage';

type IndexPageProps = {};

const IndexPage: NextPage<IndexPageProps> = ({}) => {
	return <Homepage />;
};

export default IndexPage;
