import { FC } from 'react';
import Image from 'next/image';
import RunImage from './image.png';

type RunnerImageBrokenProps = {
	className?: string;
	sizes?: string;
};

const RunnerImageBroken: FC<RunnerImageBrokenProps> = ({
	className,
	sizes,
}) => {
	return (
		<div className={className}>
			<Image src={RunImage} alt="" sizes={sizes} />
		</div>
	);
};

export default RunnerImageBroken;
