import { ChangeEventHandler, FC } from 'react';
import styles from './style.module.css';
import clsx from 'clsx';

type ToggleProps = {
	checked: boolean;
	className?: string;
	onChange?: (checked: boolean) => void;
	on?: string;
	off?: string;
};

const Toggle: FC<ToggleProps> = ({
	checked,
	className,
	onChange = () => {},
	on = 'On',
	off = 'Off',
}) => {
	const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		onChange(!event.target.checked);
	};

	/**
	 * @todo Evaluate the accessibility of this solution.
	 */
	return (
		<label className={className}>
			<input
				type="checkbox"
				checked={checked}
				className={styles.checkbox}
				onChange={handleChange}
			/>
			<div className={`${styles.toggle} ${checked ? styles.checked : ''}`}>
				<span className={styles.option}>{on}</span>
				<span className={styles.option}>{off}</span>
			</div>
		</label>
	);
};

export default Toggle;
