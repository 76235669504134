import { FC } from 'react';
import { useRouter } from 'next/router';

import sv from './sv.svg';
import en from './en.svg';

const Logo: FC = () => {
	const router = useRouter();
	const svg = router.locale === 'en' ? en : sv;

	// eslint-disable-next-line @next/next/no-img-element
	return <img src={svg.src} alt="" width={svg.width} height={svg.height} />;
};

export default Logo;
